<div kdlI18n label="editImage" matDialogTitle></div>
<div [style.padding.px]="20">

  <kdl-image-detail (changes)="changes = true" [image]="data.image"></kdl-image-detail>
</div>
<mat-dialog-actions>
  <button (click)="form.save()" [disabled]="!changes" color="primary" kdlI18n label="saveBtn" mat-raised-button
          matDialogClose=""></button>
  <button color="accent" kdlI18n label="cancelBtn" mat-stroked-button matDialogClose=""></button>
</mat-dialog-actions>
