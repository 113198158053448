import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core'
import {IProjectImage} from '../model/project-image'
import {FormControl, FormGroup} from '@angular/forms'
import {OpenProjectService} from '../../services/open-project.service'
import {ImagesService} from '../services/images.service'
import {IProject} from '../../services/project-types'
import {filter, first} from 'rxjs/operators'

@Component({
  selector: 'kdl-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss']
})
export class ImageDetailComponent implements OnInit, OnChanges {

  @Input() image: IProjectImage = {} as any

  @Output() changes: EventEmitter<boolean> = new EventEmitter<boolean>()

  public form = new FormGroup({
    customerTitle: new FormControl<string>('', {nonNullable: true}),
    factoryTitle: new FormControl<string>('', {nonNullable: true})
  })

  public viewUrl: string | undefined

  constructor(
    public openProjectService: OpenProjectService,
    public imageService: ImagesService
  ) {
  }

  public ngOnInit(): void {
    this.form.valueChanges.subscribe({
      next: () => this.changes.emit(true)
    })
  }

  public ngOnChanges(): void {
    this.form.patchValue(this.image)
    this.imageService.getViewUrl(this.image.id).subscribe({
      next: (url: string) => {
        this.viewUrl = url
        this.image.viewUrl = url
      }
    })
  }

  public save(): void {
    this.openProjectService.project$
      .pipe(filter(Boolean),
        first())
      .subscribe({
        next: (project: IProject) => {
          // This breaks in the corner cases where the image is not saved before we save here.
          const image = project.images.find(i => i.id === this.image.id)
          Object.assign(image, this.form.value)
          this.openProjectService.triggerChanges({projectChange: true})
        }
      })
  }
}
