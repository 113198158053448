import {Ti18nFile} from '../new-i18n-keys'

export const I18nEn: Ti18nFile = {
  ////////////////////////////////////////////////////////////////////////
  // TODO - Migrate when new i18n pipeline is fully established
  ////////////////////////////////////////////////////////////////////////
  'optBackPanel': 'Endpanel, back',
  'optBrassPlate': 'Brass plate with logo',
  'optCarpenterJoy': 'Corner decoration',
  'optCenterPost': 'Mid-post',
  'optCombinedUnit': 'Combined cabinet',
  'optCornice': 'Cornice',
  'optCoverSideL': 'Endpanel, left',
  'optCoverSideR': 'Endpanel, right',
  'optCuttingBoard': 'Chopping board',
  'optDoor': 'Glass door / No door / Painted inside',
  'optDoorAttachment': 'Door-on-door or sliding',
  'optDoorType': 'Style of door',
  'optDrawerDoor': 'Drawer or door option',
  'optDrawerFront': 'Drawer front style',
  'optDrawerInsert': 'Cutlery dividers, blocks etc',
  'optFanAdoption': 'Adoption for extractor',
  'optFanExtractorFront': 'Carcass side front',
  'optFanExtractorLeft': 'Carcass side left',
  'optFanExtractorRight': 'Carcass side right',
  'optFiller': 'Recesses',
  'optFrameWidth': 'Frame widths',
  'optHandleDoor': 'Turning knobs',
  'optHandleDrawer': 'Handles/Knobs for drawers',
  'optHanging': 'Hinge direction',
  'optHiddenDrawer': 'Shelves / hidden drawers',
  'optHiddenDrawerSink': 'Extra drawer',
  'optHiddenVisibleWidth': 'Visible / hidden width',
  'optHinges': 'Hinge type',
  'optLegs': 'Legs',
  'optLightning': 'Lights',
  'optMicroAdoption': 'Adoption for microwave',
  'optPaintProcess': 'Painting process',
  'optPaintSide': 'Painted side because of DW',
  'optPlinth': 'Plinth height',
  'optScribings': 'Oversizing',
  'optShelves': 'Quantity of shelves',
  'optShelvesAdjustable': 'Adjustable shelves',
  'optSkirting': 'Socle type',
  'optSpiceRack': 'Jar rack',
  'optStoveTopAdoption': 'Adoption for stove top',
  ////////////////////////////////////////////////////////////////////////
  // API
  ////////////////////////////////////////////////////////////////////////
  'api_error_conflict_dialog_body': 'Someone else has updated your project, and your version needs to be updated. You can update their changes or overwrite them with yours.',
  'api_error_conflict_dialog_body_choose': 'Please Choose:',
  'api_error_conflict_dialog_body_option_1_1': 'Force Update to overwrite the latest changes with your version.',
  'api_error_conflict_dialog_body_option_1_2': 'Their last changes will be lost.',
  'api_error_conflict_dialog_body_option_2_1': 'Reload the Page to see the latest version.',
  'api_error_conflict_dialog_body_option_2_2': 'Your last changes will be lost.',
  'api_error_conflict_dialog_force_update': 'Force update',
  'api_error_conflict_dialog_last_update': 'Last modified by {@1} at {@2}',
  'api_error_conflict_dialog_others_body': 'It seems there is a new version for the item you have tried to update.',
  'api_error_conflict_dialog_others_contact': 'Please take a screenshot and contact support (Darío?)',
  'api_error_conflict_dialog_others_url': 'Failed URL:',
  'api_error_conflict_dialog_reload_page': 'Reload page',
  'api_error_conflict_dialog_title': 'Could not save!',
  ////////////////////////////////////////////////////////////////////////
  // Appliance Info
  ////////////////////////////////////////////////////////////////////////
  'appliance': 'Appliance',
  'appliance_add_discount': 'Add discount if applicable',
  'appliance_article_number': 'Article number',
  'appliance_brand': 'Brand',
  'appliance_connected_to': 'Connected to',
  'appliance_description': 'Info (internal use only)',
  'appliance_discount': 'Discount',
  'appliance_dishwasher_valve': 'Dishwasher valve',
  'appliance_filter_label': 'Filter',
  'appliance_filter_placeholder': 'Type to filter',
  'appliance_image': 'Image',
  'appliance_image_error': 'You have to upload an image to continue',
  'appliance_image_select': 'Select image',
  'appliance_name': 'Model name',
  'appliance_old_price': 'The old price was:',
  'appliance_old_price_info': 'The price is more than 270 days old and needs to be reviewed.',
  'appliance_quantity': 'Quantity',
  'appliance_recommended': 'Recommended',
  'appliance_recommended_add': 'Mark as recommended appliance',
  'appliance_recommended_remove': 'Remove from recommended appliances',
  'appliance_supplier': 'Supplier',
  'appliance_type': 'Type',
  'appliance_url': 'Hyper text link',
  ////////////////////////////////////////////////////////////////////////
  // Appliances
  ////////////////////////////////////////////////////////////////////////
  'appliances': 'Appliances',
  'appliances_add_basket_strainer': 'Do you want to add a Basket strainer?',
  'appliances_add_to_project': 'Add to project',
  'appliances_freestanding': 'Freestanding appliances',
  'appliances_selected': 'Chosen appliances',
  ////////////////////////////////////////////////////////////////////////
  // Cabinet Info
  ////////////////////////////////////////////////////////////////////////
  'cabinet': 'Cabinet',
  'cabinet_customer_comment': 'Comment to customer',
  'cabinet_connected_appliances': 'Connected appliances',
  'cabinet_connected_appliances_select': 'Select appliances',
  'cabinet_dialog_edit_option_title': `Edit option's value`,
  'cabinet_drawer_height': 'Drawer',
  'cabinet_external_appliances_dialog_title': 'Provide a link to the appliance to be ordered',
  'cabinet_external_appliances_select': 'Customer purchases appliance themselves',
  'cabinet_factory_description': 'Description for carpentry',
  'cabinet_factory_comment': 'Comment for carpentry',
  'cabinet_height': 'Height (ex. socle)',
  'cabinet_hidden_width': 'Hidden width',
  'cabinet_lighting': 'Lighting',
  'cabinet_lighting_total': 'Total lighting products',
  'cabinet_price_customer': 'Customer',
  'cabinet_price_factory': 'Carpentry cost',
  'cabinet_price_material': 'Material',
  'cabinet_reset_settings_warning': 'This will reset all settings to default!',
  'cabinet_visible_width': 'Visible width',
  ////////////////////////////////////////////////////////////////////////
  // Cabinets
  ////////////////////////////////////////////////////////////////////////
  'cabinets': 'Cabinets',
  'cabinets_tab_comments': 'Comments',
  'cabinets_tab_info': 'Info',
  'cabinets_tab_options': 'Options',
  'cabinets_tab_connected_appliances': 'Connected Appliances',
  ////////////////////////////////////////////////////////////////////////
  // Comments
  ////////////////////////////////////////////////////////////////////////
  'comment': 'Comment',
  'comment_add_on_price_customer': 'Addition Customer Price',
  'comment_add_on_price_factory': 'Addition Carpentry Cost',
  'comment_add_on_price_material': 'Added Material Cost',
  'comment_approved': 'Approved?',
  'comment_duplicate': 'Duplicate',
  'comment_info_to_customer': 'Info to customer',
  'comment_info_to_factory': 'Info to carpentry',
  'comment_new_location': 'Select new location',
  'comments': 'Comments',
  ////////////////////////////////////////////////////////////////////////
  // Common
  ////////////////////////////////////////////////////////////////////////
  'common_add': 'Add',
  'common_are_you_sure': 'Are you sure?',
  'common_back': 'Back',
  'common_cancel': 'Cancel',
  'common_close': 'Close',
  'common_create': 'Create',
  'common_description': 'Description',
  'common_delete': 'Delete',
  'common_depth': 'Depth',
  'common_edit': 'Edit',
  'common_form_error_min': 'Value must be greater than',
  'common_form_error_required': 'This field is required',
  'common_height': 'Height',
  'common_image': 'Image',
  'common_no': 'No',
  'common_price': 'Price',
  'common_reset': 'Reset',
  'common_save': 'Save',
  'common_size': 'Size',
  'common_update': 'Update',
  'common_undo': 'Undo',
  'common_width': 'Width',
  'common_yes': 'Yes',
}
