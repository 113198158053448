import {Component, Inject, Optional} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogActions} from '@angular/material/dialog'
import {
  LoadingIndicatorComponent
} from '../../common/loading-indicator/loading-indicator.component'
import {I18nModule} from '../../i18n/i18n.module'

export interface IWaitDialogData {
  /**
   * The top heading
   */
  title: string

  /**
   * Something to display in the dialog, if any
   */
  message: string
}

@Component({
  selector: 'kdl-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  styleUrls: ['./wait-dialog.component.scss'],
  imports: [
    LoadingIndicatorComponent,
    MatDialogActions,
    I18nModule
  ],
  standalone: true
})
export class WaitDialogComponent {
  public title = 'Please wait...'
  public message: string = null

  constructor(
    // Optional until fixed
    @Inject(MAT_DIALOG_DATA) @Optional() public data: IWaitDialogData
  ) {
  }
}
