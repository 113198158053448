import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {IProjectImage} from '../model/project-image'
import {Comment} from '../../comments/model/comment'
import {FormControl} from '@angular/forms'
import {CommentsService} from '../../comments/services/comments.service'
import {TProjectItemType} from '../../project-viewer/model/project-node'
import {OpenProjectService} from '../../services/open-project.service'
import {IProject} from '../../services/project-types'
import {filter, first, map, switchMap} from 'rxjs/operators'
import {of} from 'rxjs'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'

@Component({
  selector: 'kdl-move-image-dialog',
  templateUrl: './move-image-dialog.component.html',
  styleUrls: ['./move-image-dialog.component.scss']
})
export class MoveImageDialogComponent {

  public moveSelector: FormControl<TProjectItemType> = new FormControl('PROJECT')
  public cabinetSelector: FormControl<ProdboardCabinet | null> = new FormControl(null)
  public approvedCustomer: FormControl<boolean> = new FormControl(false)
  public approvedFactory: FormControl<boolean> = new FormControl(false)
  public customerImageTitle: FormControl<string> = new FormControl(null)
  public factoryImageTitle: FormControl<string> = new FormControl(null)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { imageId: string },
    public dialogRef: MatDialogRef<MoveImageDialogComponent>,
    public openProjectService: OpenProjectService,
    private commentService: CommentsService
  ) {
  }

  public move(): void {
    this.openProjectService.project$
      .pipe(
        first(),
        map((project: IProject) => project.images.find(i => i.id === this.data.imageId)),
        filter((i: IProjectImage | undefined): i is IProjectImage => !!i),
        switchMap((image: IProjectImage) => {
            if (this.moveSelector.value === 'PROJECT') {
              image.customerTitle = this.customerImageTitle.value
              image.factoryTitle = this.factoryImageTitle.value
              return of(null)
            } else {
              const comment: Comment = new Comment()
              comment.commentHome = {id: this.cabinetSelector.value.uid, type: this.moveSelector.value}
              comment.approvedCustomer = this.approvedCustomer.value
              comment.approvedFactory = this.approvedFactory.value
              image.customerTitle = `Bild till skåp nummer ${this.cabinetSelector.value.index}`
              return this.commentService.createComment(comment).pipe(
                map((c: Comment) => c.id)
              )
            }
          }
        ))
      .subscribe(
        {
          next: (res: string | null) => {
            this.openProjectService.moveImage(this.data.imageId, res, this.moveSelector.value)
            this.dialogRef.close(true)
          }
        }
      )
  }
}
