import {Component, Input, OnInit} from '@angular/core'
import {CabinetOption, ViewOption} from '../../model/cabinet-option'
import {FormControl, FormRecord, ReactiveFormsModule} from '@angular/forms'
import {OpenProjectService} from '../../services/open-project.service'
import {CommentsService} from '../../comments/services/comments.service'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio'
import {MatOption, MatSelect} from '@angular/material/select'
import {AsyncPipe} from '@angular/common'
import {MatInput} from '@angular/material/input'
import {
  CommentListComponent
} from '../../comments/comment-list/comment-list.component'
import {
  NewCommentComponent
} from '../../comments/comment-new-button/new-comment.component'

@Component({
  selector: 'kdl-cabinet-option',
  templateUrl: './cabinet-option.component.html',
  styleUrls: ['./cabinet-option.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatLabel,
    MatRadioGroup,
    MatRadioButton,
    MatFormField,
    MatSelect,
    MatOption,
    AsyncPipe,
    MatInput,
    CommentListComponent,
    NewCommentComponent
  ],
  standalone: true
})
export class CabinetOptionComponent implements OnInit {

  @Input({required: true}) option: CabinetOption

  /**
   * For each option create a form. All forms have a comment.
   *
   * We do not put the form on the option itself. We store changes
   * to the form on the project.
   *
   */
  public viewOptionsForm = new FormRecord<FormControl<string>>({})

  constructor(
    private openProjectService: OpenProjectService,
    public commentService: CommentsService
  ) {
  }

  ngOnInit(): void {
    this.createViewOptionsForm()

    // Pass on any changes to the form to the project helper service.
    this.viewOptionsForm.valueChanges.subscribe({
      next: (data: Record<string, string>) => {
        this.option.update(data)
        this.openProjectService.cabinetOptionsChanges$.next({
          cabinet: this.option.cabinetIndex,
          name: this.option.name,
          data,
          active: this.option.active
        })
        this.setViewOptionsFormData()
      }
    })
  }

  /**
   * This function creates a form based on the ViewOptions.
   */
  private createViewOptionsForm(): void {
    this.option.viewOptions.forEach((v: ViewOption) => {
      this.viewOptionsForm
        .addControl(v.name, new FormControl<string>({value: v.selection, disabled: v.disabled}))
    })
  }

  private setViewOptionsFormData(): void {
    this.option.viewOptions.forEach(vo => {
      if (vo.disabled) {
        this.viewOptionsForm.controls[vo.name].disable({emitEvent: false})
      } else {
        this.viewOptionsForm.controls[vo.name].enable({emitEvent: false})
      }
    })
  }
}
