import {Injectable} from '@angular/core'
import {DefaultLanguage, TLanguageCode} from '../application/i18n.provider'
import {Ti18nFile} from './new-i18n-keys'
import {I18nEn} from './files/i18n.en'

const LanguageFileMap: { [key in TLanguageCode]: Ti18nFile } = {
  'en': I18nEn,
  'sv': I18nEn
}

@Injectable({
  providedIn: 'root'
})
export class NewI18nService {
  /**
   * Currently selected language.
   */
  public currentLanguage: TLanguageCode
  /**
   * Currently selected translations (i18n) file, which directly depends on
   * {@link currentLanguage}
   */
  public currentLanguageFile: Ti18nFile

  constructor() {
    this.currentLanguage = DefaultLanguage
    this.currentLanguageFile = LanguageFileMap[this.currentLanguage]
  }
}
