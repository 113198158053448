import {Component, input} from '@angular/core'
import {MatIcon, MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'

const KdlIcons = [
  'cabinet-option-adjustable-shelves',
  'cabinet-option-adoption-for-extractor',
  'cabinet-option-brass-plate',
  'cabinet-option-chopping-board',
  'cabinet-option-combined-cabinet',
  'cabinet-option-corner-decoration',
  'cabinet-option-cornice',
  'cabinet-option-cutlery-dividers',
  'cabinet-option-drawer-front-style',
  'cabinet-option-endpanel-back',
  'cabinet-option-endpanel-left',
  'cabinet-option-endpanel-right',
  'cabinet-option-frame-widths',
  'cabinet-option-glass-door-no-door-painted-inside',
  'cabinet-option-hidden-visible-width',
  'cabinet-option-hinge-direction',
  'cabinet-option-hinge-type',
  'cabinet-option-jar-rack',
  'cabinet-option-legs',
  'cabinet-option-lights',
  'cabinet-option-mid-post',
  'cabinet-option-oversizing',
  'cabinet-option-painted-side-because-of-dw',
  'cabinet-option-painting-process',
  'cabinet-option-plinth-height',
  'cabinet-option-recesses',
  'cabinet-option-shelves-hidden-drawers',
  'cabinet-option-socle-type',
  'cabinet-option-style-of-door',
  'cabinet-option-turning-knobs',
  'customer-spec',
  'factory-spec',
  'price-tag'
] as const
export type TKdlIcon = typeof KdlIcons[number]

const IconFileMap: { [key in TKdlIcon]: string } = {
  'cabinet-option-adjustable-shelves': 'assets/cabinet-option-icons/adjustable_shelves.svg',
  'cabinet-option-adoption-for-extractor': 'assets/cabinet-option-icons/adoption_for_extractor.svg',
  'cabinet-option-brass-plate': 'assets/cabinet-option-icons/brass_plate.svg',
  'cabinet-option-chopping-board': 'assets/cabinet-option-icons/chopping_board.svg',
  'cabinet-option-combined-cabinet': 'assets/cabinet-option-icons/combined_cabinet.svg',
  'cabinet-option-corner-decoration': 'assets/cabinet-option-icons/corner_decoration.svg',
  'cabinet-option-cornice': 'assets/cabinet-option-icons/cornice.svg',
  'cabinet-option-cutlery-dividers': 'assets/cabinet-option-icons/cutlery_dividers.svg',
  'cabinet-option-drawer-front-style': 'assets/cabinet-option-icons/drawer_front_style.svg',
  'cabinet-option-endpanel-back': 'assets/cabinet-option-icons/endpanel_back.svg',
  'cabinet-option-endpanel-left': 'assets/cabinet-option-icons/endpanel_left.svg',
  'cabinet-option-endpanel-right': 'assets/cabinet-option-icons/endpanel_right.svg',
  'cabinet-option-frame-widths': 'assets/cabinet-option-icons/frame_widths.svg',
  'cabinet-option-glass-door-no-door-painted-inside': 'assets/cabinet-option-icons/glass_door_no_door_painted_inside.svg',
  'cabinet-option-hidden-visible-width': 'assets/cabinet-option-icons/hidden_visible_width.svg',
  'cabinet-option-hinge-direction': 'assets/cabinet-option-icons/hinge_direction.svg',
  'cabinet-option-hinge-type': 'assets/cabinet-option-icons/hinge_type.svg',
  'cabinet-option-jar-rack': 'assets/cabinet-option-icons/jar_rack.svg',
  'cabinet-option-legs': 'assets/cabinet-option-icons/legs.svg',
  'cabinet-option-lights': 'assets/cabinet-option-icons/lights.svg',
  'cabinet-option-mid-post': 'assets/cabinet-option-icons/mid_post.svg',
  'cabinet-option-oversizing': 'assets/cabinet-option-icons/oversizing.svg',
  'cabinet-option-painted-side-because-of-dw': 'assets/cabinet-option-icons/painted_side_because_of_dw.svg',
  'cabinet-option-painting-process': 'assets/cabinet-option-icons/painting_process.svg',
  'cabinet-option-plinth-height': 'assets/cabinet-option-icons/plinth_height.svg',
  'cabinet-option-recesses': 'assets/cabinet-option-icons/recesses.svg',
  'cabinet-option-shelves-hidden-drawers': 'assets/cabinet-option-icons/shelves_hidden_drawers.svg',
  'cabinet-option-socle-type': 'assets/cabinet-option-icons/socle_type.svg',
  'cabinet-option-style-of-door': 'assets/cabinet-option-icons/style_of_door.svg',
  'cabinet-option-turning-knobs': 'assets/cabinet-option-icons/turning_knobs.svg',
  'customer-spec': 'assets/kundunderlag.svg',
  'factory-spec': 'assets/snickeriunderlag.svg',
  'price-tag': 'assets/icon_price_tag.svg'
}

@Component({
  selector: 'kdl-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  imports: [
    MatIcon
  ],
  standalone: true
})
export class IconComponent {
  public icon = input.required<TKdlIcon>()

  public static setupIcons(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    KdlIcons.forEach(icon => {
      iconRegistry.addSvgIcon(icon,
        sanitizer.bypassSecurityTrustResourceUrl(IconFileMap[icon]))
    })
  }
}
