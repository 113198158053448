import {Pipe, PipeTransform} from '@angular/core'
import {Ti18nKey} from './new-i18n-keys'
import {NewI18nService} from './new-i18n.service'

type Ti18nTransformation = 'd' | 'l' | 'u' | 'c'

@Pipe({
  name: 'ni18n',
  standalone: true
})
export class NewI18nPipe implements PipeTransform {
  constructor(private readonly i18nService: NewI18nService) {
  }

  public transform(
    i18nKey: Ti18nKey,
    transformation: Ti18nTransformation = 'd',
    ...replacements: string[]
  ): string {
    const transformationTable: { [key in Ti18nTransformation]: (s: string) => string } = {
      'd': (s) => s,
      'l': (s) => s.toLowerCase(),
      'u': (s) => s.toUpperCase(),
      'c': (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }

    // Get text from i18n key
    let text = this.i18nService.currentLanguageFile[i18nKey]
    // Do any replacement needed
    replacements?.forEach((replacement: string, index: number) => {
      text = text.replace(`{@${index + 1}}`, replacement)
    })
    return transformationTable[transformation](text)
  }
}
