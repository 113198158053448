<div class="holder">
  <div (keydown.enter)="editComment()" (click)="editComment()" class="comment">
    @if (customer) {
      <div>
        @if (comment.comment) {
          <span class="comment-text">{{ comment.comment }}</span>
        } @else {
          <div class="foreign-warning" kdlI18n
               label="customerCommentMissing"></div>

          <div class="foreign-comment">
            <span kdlI18n
                  label="commentsFactoryComment"></span>: {{ comment.translation }}
          </div>
        }
      </div>
    }
    @if (factory) {
      <div>
        @if (comment.translation) {
          <span>{{ comment.translation }}</span>
        } @else {
          <div class="foreign-warning" kdlI18n
               label="factoryCommentMissing"></div>

          <div class="foreign-comment">
            <span kdlI18n
                  label="commentsCustomerComment"></span>: {{ comment.comment }}
          </div>
        }
      </div>
    }
  </div>

  <div class="filler"></div>

  @if (!comment.factoryOnly && (customer || approvals)) {
    <mat-icon
      class="material-icons-outlined approvals-icon"
      (click)="editComment()"
      (keydown.enter)="editComment()"
      [ngClass]="{'red': !comment.approvedCustomer, 'green': comment.approvedCustomer}"
    >edit_outline
    </mat-icon>
  }

  @if (!comment.customerOnly && (factory || approvals)) {
    <mat-icon
      class="material-icons-outlined approvals-icon"
      (click)="editComment()"
      (keydown.enter)="editComment()"
      [ngClass]="{'red': !comment.approvedFactory, 'green': comment.approvedFactory}"
    >edit
    </mat-icon>
  }

  <div class="prices">
    @if (showPrice) {
      <div class="price-holder">
        @if (isFirst) {
          <div class="price-tag" kdlI18n label="price"></div>
        }
        <div>{{ comment.price }} kr</div>
      </div>
    }
    @if (showLabor) {
      <div class="price-holder">
        @if (isFirst) {
          <div class="price-tag">Carp.</div>
        }
        <div>{{ comment.labor }} €</div>
      </div>
    }
    @if (showMaterial) {
      <div class="price-holder">
        @if (isFirst) {
          <div class="price-tag" kdlI18n label="material"></div>
        }
        <div>{{ comment.material }} kr</div>
      </div>
    }
  </div>

  @if (edit) {
    <div class="buttons">
      <button
        (click)="delete(comment)"
        color="warn"
        mat-icon-button>
        <mat-icon class="material-icons-outlined">delete_outline</mat-icon>
      </button>
    </div>
  }
</div>
