<h4 matDialogTitle>{{ 'cabinet_dialog_edit_option_title' | ni18n }}</h4>

<mat-dialog-content>
  <kdl-cabinet-option [option]="data.option"></kdl-cabinet-option>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="dialogRef.close()" color="accent" mat-stroked-button>
    <mat-icon class="material-icons-outlined">close</mat-icon>
    {{ 'common_close' | ni18n }}
  </button>
</mat-dialog-actions>
