@if (data) {
  <div [label]="data.title" class="title" kdlI18n></div>
}

<div class="holder">
  <kdl-loading-indicator></kdl-loading-indicator>
</div>

@if (data && data.message) {
  <mat-dialog-actions>
    {{ data.message }}
  </mat-dialog-actions>
}
