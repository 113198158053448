import {Injectable} from '@angular/core'
import {OpenProjectService} from '../../services/open-project.service'
import {TProjectItemType} from '../model/project-node'
import {filter, switchMap} from 'rxjs/operators'
import {IProject} from '../../services/project-types'
import {ICounterTop} from '../../counter-top/model/counter-top'
import {Appliance} from '../../appliances/model/appliance'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {ReplaySubject} from 'rxjs'
import {CommentsService} from '../../comments/services/comments.service'
import {IComment} from '../../comments/model/comment'
import {IProjectImage} from '../../images/model/project-image'
import {ProjectNode} from '../model/project-node.class'

@Injectable({
  providedIn: 'root'
})
export class ProjectViewerService {

  /**
   * Emit a material Tree compatible tree of shite.
   */
  public project$: ReplaySubject<ProjectNode[]> = new ReplaySubject<ProjectNode[]>(1)

  /**
   * A public map of all IDs where you can retrieve the
   * node and actual object this node represent by id
   */
  public nodes: Map<string, ProjectNode> = new Map<string, ProjectNode>()

  constructor(
    private projectService: OpenProjectService,
    private commentsService: CommentsService
  ) {
    let newProject: ProjectNode[]
    this.projectService.project$.pipe(
      filter(Boolean),
      switchMap((project: IProject) => {
        this.nodes.clear()
        newProject = [ProjectNode.fromProject(project)]
        const images = project.images.map((image: IProjectImage) =>
          ProjectNode.fromImage(image))
        newProject[0].children.push(ProjectNode.createRoot('images', images, 'IMAGE'))
        images.forEach(i => this.nodes.set(i.id, i))

        const counterTops = project.counterTops.map((counterTop: ICounterTop) =>
          ProjectNode.fromCounterTop(counterTop))

        newProject[0].children.push(ProjectNode.createRoot('counterTops', counterTops, 'COUNTER_TOP'))
        counterTops.forEach(ct => this.nodes.set(ct.id, ct))

        const appliances =
          project.appliances.map((appliance: Appliance) =>
            ProjectNode.fromAppliance(appliance))
        newProject[0].children.push(ProjectNode.createRoot('appliances', appliances, 'APPLIANCE'))
        appliances.forEach(a => this.nodes.set(a.id, a))
        return this.commentsService.projectComments$
      }),
      switchMap((comments: IComment[]) => {
        const commentList = comments.map((comment: IComment) =>
          ProjectNode.fromComment(comment))
        newProject[0].children.push(
          ProjectNode.createRoot('comments', commentList, 'COMMENT'))
        commentList.forEach(c => this.nodes.set(c.id, c))
        return this.projectService.cabinets$
      })
    ).subscribe({
      next: (cabs: ProdboardCabinet[]) => {
        const cabinets = cabs.map((cab: ProdboardCabinet) => {
          const c: ProjectNode = ProjectNode.fromCabinet(cab)
          this.nodes.set(cab.uid, c)

          c.children = cab.options
            .filter(o => o.active)
            .map(option => ProjectNode.fromOption(option, cab, c))
          c.children.forEach(o => this.nodes.set(o.id, o))
          return c
        })
        this.addTooComplexShite(newProject[0], 'cabinets', cabinets, 'CABINET')
        // Remove all top level items that have no children
        newProject[0].children = newProject[0].children.filter(n => n.children.length > 0)
        this.project$.next(newProject)
      }
    })
  }

  private addTooComplexShite(node: ProjectNode, name: string, children: ProjectNode[], type: TProjectItemType): void {
    node.children.unshift(ProjectNode.createRoot(name, children, type))
    node.children = node.children.filter(n => n.children.length > 0)
  }
}
