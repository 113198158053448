import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {IProjectDialogData} from '../model/project-dialog-data'

@Component({
  selector: 'kdl-project-item-dialog',
  templateUrl: './project-item-dialog.component.html'
})
export class ProjectItemDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProjectDialogData,
    public dialogRef: MatDialogRef<ProjectItemDialogComponent>
  ) {
  }
}
