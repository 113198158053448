import {Component, Inject} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'
import {NewI18nPipe} from '../../i18n/new-i18n.pipe'
import {Ti18nKey} from '../../i18n/new-i18n-keys'
import {SafeHtml} from '@angular/platform-browser'

interface IConfirmDialogData {
  text?: Ti18nKey
  html?: string | SafeHtml
}

@Component({
  selector: 'kdl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    NewI18nPipe
  ],
  standalone: true
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData
  ) {
  }

  public static open(
    dialog: MatDialog,
    data: IConfirmDialogData,
    disableClose: boolean = false
  ): MatDialogRef<ConfirmDialogComponent> {
    return dialog.open(ConfirmDialogComponent, {
      data,
      disableClose,
      maxWidth: '600px'
    })
  }
}
