import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {ImagesService} from '../services/images.service'
import {IProjectImage} from '../model/project-image'
import {OpenProjectService} from '../../services/open-project.service'
import {MatDialog} from '@angular/material/dialog'
import {
  ImageDetailDialogComponent
} from '../image-detail-dialog/image-detail-dialog.component'
import {of} from 'rxjs'
import {filter, switchMap} from 'rxjs/operators'
import {
  ImageViewDialogComponent
} from '../image-view-dialog/image-view-dialog.component'
import {
  MoveImageDialogComponent
} from '../move-image-dialog/move-image-dialog.component'
import {MatTooltip} from '@angular/material/tooltip'
import {AsyncPipe} from '@angular/common'
import {I18nPipe} from '../../i18n/i18n/i18n.pipe'
import {MatIcon} from '@angular/material/icon'
import {MatIconButton} from '@angular/material/button'

@Component({
  selector: 'kdl-image-list-item',
  templateUrl: './image-list-item.component.html',
  styleUrls: ['./image-list-item.component.scss'],
  imports: [
    MatTooltip,
    I18nPipe,
    AsyncPipe,
    MatIcon,
    MatIconButton
  ],
  standalone: true
})
export class ImageListItemComponent implements OnChanges {

  /**
   * The image you want to display
   */
  @Input() image: IProjectImage = {} as any

  /**
   * Show thumbnail or not, defaults to false
   */
  @Input() thumbs = false

  /**
   * Show an indicator if the image will show up in what PDF
   */
  @Input() showLocation = false

  /**
   * Show image source
   */
  @Input() source = false

  @Output() removed = new EventEmitter<boolean>()
  /**
   * Set this locally to avoid it being overwritten by save
   */
  public viewUrl: string | undefined

  constructor(
    public imageService: ImagesService,
    public openProjectService: OpenProjectService,
    private dialog: MatDialog
  ) {
  }

  public ngOnChanges(): void {
    of(this.thumbs).pipe(
      filter(Boolean),
      switchMap(() => this.imageService.getViewUrl(this.image.id))
    ).subscribe({
      next: (url: string) => {
        this.image.viewUrl = url
        this.viewUrl = url
      }
    })
  }

  public viewDetails(): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.image
      }
    })
  }

  public viewImage(): void {
    this.dialog.open(ImageViewDialogComponent, {
      data: {
        image: this.viewUrl
      }
    })
  }

  public moveImage(): void {
    this.dialog.open(MoveImageDialogComponent, {
      data: {
        imageId: this.image.id
      }
    })
  }
}
