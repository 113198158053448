import {Comment, CommentHomeEntity, Comments, IComment} from '../model/comment'
import {IProject} from '../../services/project-types'
import {Appliance} from '../../appliances/model/appliance'
import {CounterTop} from '../../counter-top/model/counter-top'

export class ProjectCommentHelper {

  private readonly project: IProject

  constructor(project: IProject) {
    this.project = project
  }

  public addComment(comment: Comment): void {
    let comments: Comment[]

    const legacy = this.convertToLegacy(comment.commentHome)

    if (typeof legacy !== 'string') {
      comments = this.getTarget(legacy.cabinetIndex, legacy.optionName)
    } else {
      comments = this.getCommentsFromComment(comment)
    }
    // Avoid duplications. If a move on a non existing comment is made
    // Must manipulate the array in place since this is a reference
    const index = comments.findIndex(c => c.id === comment.id)
    if (index !== -1) {
      // Remove the existing item
      comments.splice(index, 1)
    }
    comments.push(comment)
  }

  public removeComment(comment: Comment): void {
    let comments: Comment[]
    const legacy = this.convertToLegacy(comment.commentHome)
    if (typeof legacy !== 'string') {
      comments = this.getTarget(legacy.cabinetIndex, legacy.optionName)
    } else {
      comments = this.getCommentsFromComment(comment)
    }

    comments = comments.filter((c: IComment) => {
      if (c.id !== comment.id) {
        return true
      }
      return c.version !== comment.version
    })

    if (typeof legacy !== 'string') {
      this.setTarget(legacy.cabinetIndex, legacy.optionName, comments)
    } else {
      const item = this.getCommentsItem(comment)
      item.comments = comments
    }
  }

  private getTarget(cabinetIndex: number | null, optionName: string | null): Comment[] {
    if (cabinetIndex) {
      if (optionName) {
        if (this.project.cabinets[cabinetIndex][optionName]) {
          this.project.cabinets[cabinetIndex][optionName].comments = this.project.cabinets[cabinetIndex][optionName].comments || []
          return this.project.cabinets[cabinetIndex][optionName].comments
        } else {
          this.project.cabinets[cabinetIndex][optionName] = {comments: []}
          return this.project.cabinets[cabinetIndex][optionName].comments
        }
      } else {
        this.project.cabinets[cabinetIndex].comments = this.project.cabinets[cabinetIndex].comments || []
        return this.project.cabinets[cabinetIndex].comments
      }
    }
    this.project.comments = this.project.comments || []
    return this.project.comments
  }

  /**
   * Set target can only be called after get target.
   */
  private setTarget(cabinetIndex: number | null, optionName: string | null, comments: IComment[]): void {
    let target: IComment[] = this.project.comments
    if (cabinetIndex) {
      if (optionName) {
        target = this.project.cabinets[cabinetIndex][optionName].comments
      } else {
        target = this.project.cabinets[cabinetIndex].comments
      }
    }
    target.length = 0
    comments.forEach((comment: IComment) => target.push(comment))
  }

  /**
   * Converts a home to a Cabinet or Option location,
   * since options do not exist in their own right.
   *
   * @param commentHome
   * @private
   */
  private convertToLegacy(commentHome: CommentHomeEntity):
    { cabinetIndex: number | null; optionName: string | null } | string {
    if (commentHome.type === 'OPTION') {
      const parts = commentHome.id.split('_')
      const cabinetId = parts.shift()
      const optionName = parts.join('_')
      return {
        cabinetIndex: this.cabinetIdToIndex(cabinetId),
        optionName
      }
    }

    if (commentHome.type === 'CABINET') {
      return {
        cabinetIndex: this.cabinetIdToIndex(commentHome.id),
        optionName: null
      }
    }

    if (commentHome.type === 'PROJECT') {
      return {cabinetIndex: null, optionName: null}
    }

    return commentHome.id
  }

  private cabinetIdToIndex(id: string): number {
    const cabinetIndex = Object.keys(this.project.cabinets).find((k: string) => this.project.cabinets[k].uid === id)
    return +cabinetIndex
  }

  /**
   * Locate the comments array based on what type of comment it is.
   */
  private getCommentsFromComment(comment: Comment): Comment[] {
    const item = this.getCommentsItem(comment)
    return item ? item.comments : []
  }

  private getCommentsItem(comment: Comment): Comments {
    let item: Comments = {comments: [], commentHome: {id: '', type: 'PROJECT'}}
    if (comment.commentHome.type === 'APPLIANCE') {
      item = this.project.appliances.find((a: Appliance) => a.id === comment.commentHome.id)
    }

    if (comment.commentHome.type === 'COUNTER_TOP') {
      item = this.project.counterTops.find((c: CounterTop) => c.id === comment.commentHome.id)
    }
    return item
  }
}
