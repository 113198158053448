<div kdlI18n label="newLocation" matDialogTitle>{{ data.title }}</div>
<div matDialogContent>
  <kdl-project-item-selector
    (selection)="dialogRef.close($event)"
    [filterNodes]="data.filter"
    [selected]="data.selected"
    [startItem]="data.start"
  ></kdl-project-item-selector>
</div>
<mat-dialog-actions>
  <button [matDialogClose]="null" kdlI18n label="cancelBtn" mat-stroked-button></button>
</mat-dialog-actions>
