@for (comment of comments; track $index) {
  <kdl-comment-list-item
    [approvals]="approvals"
    [comment]="comment"
    [edit]="edit"
    [isFirst]="$first"
    [showLabor]="showLabor"
    [showMaterial]="showMaterial"
    [showPrice]="showPrice"
    class="list-item"
  ></kdl-comment-list-item>
}
