import {Component, Input} from '@angular/core'
import {CommentsService} from '../services/comments.service'
import {Comment, CommentHomeEntity} from '../model/comment'
import {MatDialog} from '@angular/material/dialog'
import {CommentsComponent} from '../comments/comments.component'
import {filter, switchMap} from 'rxjs/operators'
import {MatIcon} from '@angular/material/icon'
import {MatIconButton} from '@angular/material/button'

@Component({
  selector: 'kdl-new-comment',
  templateUrl: './new-comment.component.html',
  styleUrls: ['./new-comment.component.scss'],
  imports: [
    MatIcon,
    MatIconButton
  ],
  standalone: true
})
export class NewCommentComponent {

  /**
   * This should be to comment home entity.
   * It is basically mandatory
   */
  @Input() source: CommentHomeEntity

  @Input() customerOnly?: boolean

  @Input() factoryOnly?: boolean

  constructor(
    private commentsService: CommentsService,
    private dialog: MatDialog,
  ) {
  }

  public add(): void {
    const comment = new Comment()
    comment.factoryOnly = this.factoryOnly
    comment.customerOnly = this.customerOnly
    comment.commentHome = this.source
    this.dialog.open(CommentsComponent, {data: {comment, duplicate: false}})
      .afterClosed().pipe(
      filter(Boolean),
      switchMap((newComment: Comment) =>
        this.commentsService.createComment(newComment)))
      .subscribe()
  }
}
