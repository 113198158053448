import {TLanguageCode} from '../../application/i18n.provider'
import {TSettingOptionUseCase} from '../../services/settings-item.service'
import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'
import {CabinetOption, TOptionSelectName} from '../cabinet-option'
import {Lighting} from '../lighting'
import {HandleDoor} from '../handle-door'

import {CLASSIC_HINGES, DOOR_NO_DOOR_SELECTED} from '../model-types'
import {DoorType, DoorTypeStopperOptions, TDoorTypeStoppers} from '../door-type'
import {Hinges} from '../hinges'
import {Door} from '../door'
import {CenterPost} from '../center-post'

interface CountMap {
  [key: string]: number[]
}

export interface SummaryProperties {
  description: string
  cabinetNumbers: number[]
}

export class ProjectOption {
  /**
   * Tells if any cabinet have lightning
   */
  public lightning = false

  /**
   * Needed configuration to request i18n texts for CabinetOptions
   */
  private readonly useCase: TSettingOptionUseCase
  private readonly lc: TLanguageCode

  private skirtCount: CountMap = {}

  private doorCount: CountMap = {}

  private coverSideCount: CountMap = {}

  private drawerFrontCount: CountMap = {}

  private hingeCount: CountMap = {}

  private paintCount: CountMap = {}

  private shelvesCount: CountMap = {}

  private centerPostCount: CountMap = {}

  private stoppersAndCatchersCount: CountMap = {}

  private scribingsCount: CountMap = {}

  private countMap: Map<TOptionSelectName, CountMap>

  constructor(
    cabinets: ProdboardCabinet[],
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ) {
    // Set needed configuration for i18n CabinetOptions texts
    this.lc = lc
    this.useCase = useCase

    // Initialise count map
    this.countMap = new Map<TOptionSelectName, CountMap>([
      ['Skirting', this.skirtCount],
      ['DoorType', this.doorCount],
      ['CoverSide', this.coverSideCount],
      ['DrawerFront', this.drawerFrontCount],
      ['Hinges', this.hingeCount],
      ['PaintProcess', this.paintCount],
      ['Shelves', this.shelvesCount],
      ['CenterPost', this.centerPostCount],
      ['Scribings', this.scribingsCount]
    ])

    cabinets
      .map((cabinet: ProdboardCabinet) => {
        cabinet.options
          // Only take active options that we have a map for
          .filter(option => this.countMap.has(option.optionSelectName) && option.active)
          // Skip Doors that have no door.
          .filter(o => !(o.optionSelectName === 'DoorType' && o.viewOptions[0].selection === DOOR_NO_DOOR_SELECTED))
          .forEach((option: CabinetOption) => {
            this.createSummary(this.countMap.get(option.optionSelectName), option, cabinet.index)
          })
        /**
         * This is special since we only need to know IF any lightning is active
         */
        this.lightning = this.lightning || !!cabinet.getActiveOption<Lighting>('Lighting')
        return cabinet
      })
      .filter((cabinet: ProdboardCabinet) => cabinet.hasStoppers)
      .forEach((cabinet: ProdboardCabinet) => {
        this.setStoppersAndCatchers(cabinet)
      })
  }

  public getSkirting(): SummaryProperties[] {
    return this.getFromSummary(this.skirtCount)
  }

  public getDoors(): SummaryProperties[] {
    return this.getFromSummary(this.doorCount)
  }

  public getCoverSides(): SummaryProperties[] {
    return this.getFromSummary(this.coverSideCount)
  }

  public getDrawerFront(): SummaryProperties[] {
    return this.getFromSummary(this.drawerFrontCount)
  }

  public getHinges(): SummaryProperties[] {
    return this.getFromSummary(this.hingeCount)
  }

  public getPaints(): SummaryProperties[] {
    return this.getFromSummary(this.paintCount)
  }

  public getShelves(): SummaryProperties[] {
    return this.getFromSummary(this.shelvesCount)
  }

  public getCenterPost(): SummaryProperties[] {
    return this.getFromSummary(this.centerPostCount)
  }

  public getScribings(): SummaryProperties[] {
    return this.getFromSummary(this.scribingsCount)
  }

  public getCatchersAndStoppers(): SummaryProperties[] {
    return this.getFromSummary(this.stoppersAndCatchersCount)
  }

  private createSummary(prop: {
    [key: string]: number[]
  }, option: CabinetOption, cabinetNumber: number): void {
    const value = option.getTextForProjectSummary(this.useCase, this.lc)
    prop[value] = prop[value] || []
    prop[value].push(cabinetNumber)
  }

  private getFromSummary(prop: {
    [key: string]: number[]
  }): SummaryProperties[] {
    const res: any = []
    const keys = Object.keys(prop)
    keys
      .filter((s: string) => s)
      .forEach((key: string) => {
        const cabinetNumbers: number[] = prop[key]
        res.push(
          {
            description: key,
            cabinetNumbers
          }
        )
      })
    return res
  }

  private setStoppersAndCatchers(cabinet: ProdboardCabinet): void {
    const doorType = cabinet.getActiveOption<DoorType>('DoorType')
    const door = cabinet.getActiveOption<Door>('Door')
    const hinges = cabinet.getActiveOption<Hinges>('Hinges')

    // If there is no door (door not selected), or hinges or door-type, it
    // has nothing to do, so just return.
    if (!doorType || !hinges || door?.viewOptions[0].selection === DOOR_NO_DOOR_SELECTED) {
      return
    }

    const handles: HandleDoor = cabinet.getActiveOption<HandleDoor>('HandleDoor')
    const centerPost: CenterPost = cabinet.getActiveOption<CenterPost>('CenterPost')

    // Get the possible type of stoppers & magnets
    let type: TDoorTypeStoppers
    // Cabinets with "Classic hinges" with either:
    //  - No handles
    //  - Handles are turning knobs and cabinet has fake-mid-post
    // will need both, stoppers and magnets
    if (hinges.viewOptions[0].selection === CLASSIC_HINGES &&
      (!handles || (handles.isVred() && centerPost?.isFakeMidPost))) {
      type = 'both'
    }
    // Otherwise, it just depends on if the door type needs stoppers
    else {
      type = doorType.needStoppers ? 'only-stoppers' : 'none'
    }

    // If we have a type at the end of the inspection, we set it
    if (type) {
      const key = DoorTypeStopperOptions[type][this.lc]
      this.stoppersAndCatchersCount[key] = this.stoppersAndCatchersCount[key] || []
      this.stoppersAndCatchersCount[key].push(cabinet.index)
    }
  }
}
