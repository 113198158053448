import {IProjectNode, TProjectItemType} from './project-node'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {IComment} from '../../comments/model/comment'
import {Appliance} from '../../appliances/model/appliance'
import {ICounterTop} from '../../counter-top/model/counter-top'
import {IProjectImage} from '../../images/model/project-image'
import {IProject} from '../../services/project-types'
import {CabinetOption} from '../../model/cabinet-option'

export class ProjectNode implements IProjectNode {
  public children: ProjectNode[] = []
  id: string = ''
  item: any
  name: string
  nameParts: string[]
  selected: boolean
  type: TProjectItemType

  /**
   * Do not create this, use the "from".
   */
  private constructor() {
  }

  public static fromProject(project: IProject) {
    const item = new ProjectNode()
    item.type = 'PROJECT'
    item.name = project.customer.name
    return item
  }

  public static fromCabinet(cabinet: ProdboardCabinet) {
    const item = new ProjectNode()
    item.type = 'CABINET'
    item.item = cabinet
    item.id = cabinet.uid
    item.nameParts = [cabinet.index + '. ' + cabinet.cat]
    item.name = cabinet.index + '. ' + cabinet.cat
    return item
  }

  public static fromOption(option: CabinetOption, cabinet: ProdboardCabinet, node: ProjectNode) {
    const item = new ProjectNode()
    item.name = option.title
    item.type = 'OPTION'
    item.id = `${cabinet.uid}_${option.name}`
    item.item = option
    item.nameParts = [node.name, option.title]
    return item
  }

  public static fromComment(comment: IComment) {
    const item = new ProjectNode()
    item.type = 'COMMENT'
    item.item = comment
    item.id = comment.id
    item.name = comment.translation
    return item
  }

  public static fromAppliance(appliance: Appliance) {
    const item = new ProjectNode()
    item.type = 'APPLIANCE'
    item.item = appliance
    item.id = appliance.id
    item.name = appliance.applianceTypeName
    return item
  }

  public static fromCounterTop(counterTop: ICounterTop) {
    const item = new ProjectNode()
    item.type = 'COUNTER_TOP'
    item.item = counterTop
    item.id = counterTop.id
    item.name = counterTop.name
    return item
  }

  public static fromImage(image: IProjectImage) {
    const item = new ProjectNode()
    item.type = 'IMAGE'
    item.item = image
    item.id = image.id
    item.name = image.name
    return item
  }

  public static createRoot(name: string, children: ProjectNode[], type: TProjectItemType) {
    const item = new ProjectNode()
    item.name = name
    item.children = children
    item.type = type
    return item
  }
}