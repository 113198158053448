import {TProjectItemType} from '../../project-viewer/model/project-node'


export interface IComment {

  /**
   * UUID of this saved comment.
   */
  id?: string

  /**
   * Version for database purposes.
   */
  version: number

  /**
   * The comment in Swedish
   */
  comment: string

  /**
   * The translation to english...
   */
  translation: string

  approvedCustomer: boolean

  approvedFactory: boolean

  price: number

  material: number

  labor: number

  /**
   * A comment belongs to something. But an entity
   * will not anymore have comments. Comments
   */
  commentHome: CommentHomeEntity

  cabinetIndex: number | null | undefined

  optionName: string | null | undefined

  /**
   * Selectors for choosing if this is a comment
   * that does not have translations or vice versa.
   */
  factoryOnly?: boolean

  customerOnly?: boolean
}

export interface CommentHomeEntity {
  /**
   * New name of type
   */
  type: TProjectItemType

  /**
   * The ID is on of:
   * 1. The project id, then it is a project wide Comment
   * 2. Any arbitrary ID from E.g. an appliance, counter top etc.
   * 3. If cabinet it is the cabinet UUID, If no UUID we set it to FAKE_UUID_<cabinet-index>
   * 4. The Option "name" if an option on a cabinet
   * 5. unset - if not defined
   */
  id: string

  /**
   * A text representation for where this comment is located.
   */
  location?: string
}

export interface Comments {
  comments: Comment[]

  commentHome: CommentHomeEntity
}

export class Comment implements IComment {
  public id: string = self.crypto.randomUUID()
  public version = 0
  public translation = ''

  public approvedCustomer = false
  public approvedFactory = false
  public price = 0
  public labor = 0
  public material = 0
  public cabinetIndex: number | null | undefined = null
  public optionName: string | null | undefined = null

  public commentHome: CommentHomeEntity = {id: 'unset', type: '' as any}


  public customerOnly?: boolean

  public factoryOnly?: boolean

  constructor(public comment: string = '') {
    this.comment = comment
  }

  /**
   * Make a copy of the comment and return a new comment
   * with the home set to undefined.
   */
  public duplicate(input: Comment): Comment {
    // We must preserve the id, meaning that this comment is already created?!
    // Better create a new one here??!
    const id = this.id
    // Make a real copy so that we do not carry sub data inadvertently
    Object.assign(this, JSON.parse(JSON.stringify(input)))
    // Restore the id.
    this.id = id
    this.commentHome.location = undefined
    this.commentHome.id = undefined
    return this
  }
}
