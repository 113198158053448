import {Inject} from '@angular/core'
import {FILE_READER} from './fileReader.provider'
import {take} from 'rxjs/operators'
import {Observable, ReplaySubject, Subject} from 'rxjs'
import {IFileUpload} from '../images/services/images.service'

/**
 * Abstract the file uploading so that you can upload
 * images from here and there.
 *
 * Please review if it can be used in Product Database (import)
 * and other places where we upload "binary" objects.
 *
 */
export class Uploader {

  public uploading = false
  /**
   * This is where the subclasses get a stream of file
   * objects.
   */
  protected result$: Observable<IFileUpload>

  /**
   * This is the complete file uploaded with all metadata.
   *
   */
  private fileData$ = new Subject<IFileUpload>()

  /**
   * Here we publish the metadata so that the file reader
   * can "remember" that.
   */
  private metaData$ = new ReplaySubject(1)

  constructor(
    @Inject(FILE_READER) private fileReader: FileReader
  ) {
    // Set the callback for the data is read complete
    this.fileReader.onloadend = this.onLoadEnd

    // Let the result be the observable of our subject
    this.result$ = this.fileData$.asObservable()
  }

  public onFileSelected(event: EventTarget): void {
    this.uploading = true
    const selectedFiles: FileList = (event as HTMLInputElement).files
    if (selectedFiles) {
      const files = Array.from(selectedFiles)
      this.processFile(files)
      this.fileData$.pipe(
        take(selectedFiles.length - 1)
      ).subscribe({
        next: () => {
          this.processFile(files)
        }
      })
    }
  }

  private processFile(files: File[]): void {
    const file = files.shift()
    this.metaData$.next(file)
    this.fileReader.readAsArrayBuffer(file)
  }

  private onLoadEnd = (): any => {
    const result = this.fileReader.result as ArrayBuffer
    this.metaData$.pipe(
      take(1)
    ).subscribe({
      next: (file: File) => {
        // Convert to a "Blob" which is what we are sending. Note that ArrayBuffers
        // can be used directly but we explicitly convert it to unsigned bytes in case
        // we are running on a platform with different internal representation
        const imageData = new Blob([new Uint8Array(result)], {type: file.type})
        this.fileData$.next({name: file.name, contentType: file.type, imageData})
      }
    })

  }
}
