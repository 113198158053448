<div class="holder">
  <div>
    <div *ngIf="image.scope === 'PROJECT'" class="note">
      <span><strong>NOTE: </strong></span><span kdlI18n label="imagePresentationText"></span>
    </div>

    <form [formGroup]="form">
      <mat-form-field>
        <mat-label kdlI18n label="customerTitle"></mat-label>
        <input formControlName="customerTitle" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label kdlI18n label="factoryTitle"></mat-label>
        <input formControlName="factoryTitle" matInput>
      </mat-form-field>
    </form>
  </div>
  <div class="image-holder">
    <img [src]="viewUrl || './assets/photo_white.png'" alt="image">
  </div>
</div>
