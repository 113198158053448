import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core'
import {IFileUpload, ImagesService} from '../services/images.service'
import {
  FILE_READER,
  fileReaderProvider
} from '../../application/fileReader.provider'
import {mergeMap} from 'rxjs/operators'
import {OpenProjectService} from '../../services/open-project.service'
import {Uploader} from '../../application/uploader'
import {IProjectImage} from '../model/project-image'
import {TProjectItemType} from '../../project-viewer/model/project-node'
import {MatButton} from '@angular/material/button'
import {I18nModule} from '../../i18n/i18n.module'
import {
  LoadingIndicatorComponent
} from '../../common/loading-indicator/loading-indicator.component'
import {MatIcon} from '@angular/material/icon'

@Component({
  selector: 'kdl-blueprint-upload',
  templateUrl: './blueprint-upload.component.html',
  styleUrls: ['./blueprint-upload.component.scss'],
  providers: [fileReaderProvider],
  imports: [
    MatButton,
    MatIcon,
    I18nModule,
    LoadingIndicatorComponent
  ],
  standalone: true
})
export class BlueprintUploadComponent extends Uploader implements OnInit {

  /**
   * Historically all images have been scoped "BLUEPRINT", this
   * changes, and you can optionally input a different scope.
   *
   * The scope decides where, and how, the image is displayed.
   */
  @Input() scope: TProjectItemType = 'PROJECT'

  @Input() customerTitle = ''
  @Input() factoryTitle = ''
  /**
   * If you like, tie this item to something else.
   * Someone else is responsible for knowing what it
   * is
   */
  @Input() sourceId = ''

  /**
   * So we can have different text on the upload button.
   * Remember to user the translation!
   */
  @Input() uploadButtonLabel = 'selectImagesBtn'

  /**
   * If you are inclined to show an icon add it here.
   * It should be a Material Icon short name like "delete" or "add_image" etc.
   */
  @Input() iconForButton: string | undefined
  /**
   * Here we emit all images _after_ they are created.
   * There might be more than one but we emit them piece
   * by piece.
   */
  @Output() images: EventEmitter<IProjectImage> = new EventEmitter<IProjectImage>()

  constructor(private imagesService: ImagesService,
              private openProjectService: OpenProjectService,
              @Inject(FILE_READER) fileReader: FileReader) {
    super(fileReader)
  }

  public ngOnInit(): void {
    this.result$.pipe(
      mergeMap((data: IFileUpload) => this.imagesService.uploadImageData(data))
    ).subscribe({
      next: (data: IFileUpload) => {
        // Sending ready data
        const image: IProjectImage = {
          displayName: data.name,
          id: data.id,
          name: data.name,
          scope: this.scope,
          customerTitle: this.customerTitle,
          factoryTitle: this.factoryTitle,
          viewUrl: '',
          sourceId: this.sourceId
        }
        // The image is created. It now has an id.
        this.openProjectService.addImage(image)
        this.uploading = false
        this.images.emit(image)
      }
    })
  }
}
