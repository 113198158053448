<div matDialogTitle>{{ 'common_are_you_sure' | ni18n }}</div>

@if (data.text) {
  <div matDialogContent>{{ data.text | ni18n }}</div>
}
@if (data.html) {
  <div [innerHtml]="data.html" matDialogContent></div>
}

<mat-dialog-actions>
  <button [mat-dialog-close]="true" color="warn"
          mat-stroked-button>{{ 'common_yes' | ni18n }}
  </button>

  <button matDialogClose="" color="accent"
          mat-raised-button>{{ 'common_no' | ni18n }}
  </button>
</mat-dialog-actions>
