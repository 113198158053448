import {Component} from '@angular/core'

@Component({
  selector: 'kdl-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true
})
export class LoadingIndicatorComponent {
}
