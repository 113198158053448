import {Component, Inject, ViewChild} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {IProjectImageDialogData} from '../model/project-image-dialog'
import {ImageDetailComponent} from '../image-detail/image-detail.component'

@Component({
  selector: 'kdl-image-detail-dialog',
  templateUrl: './image-detail-dialog.component.html'
})
export class ImageDetailDialogComponent {

  @ViewChild(ImageDetailComponent) public form: ImageDetailComponent

  /**
   * Only used to light up save button.
   */
  public changes = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProjectImageDialogData
  ) {
  }
}
