<div class="header" matDialogTitle>
  <div>{{ 'comment' | ni18n }}</div>

  <div class="filler"></div>

  @if (form.pristine && canDuplicate) {
    <button (click)="duplicate()"
            color="accent" mat-button>
      <mat-icon class="material-icons-outlined">copy</mat-icon>
      <span>{{ 'comment_duplicate' | ni18n }}</span>
    </button>
  }

  <span class="location"
  >{{ comment.commentHome.location | i18n | async }}</span>

  <button (click)="editLocation()"
          color="accent"
          mat-stroked-button
  >{{ 'comment_new_location' | ni18n }}
  </button>
</div>

<mat-dialog-content>
  <div class="search">
    <div class="form-full-width">
      <div class="info">{{ 'comment_info_to_customer' | ni18n }}</div>
    </div>

    <div [style.width.px]="20"></div>

    @if (!comment.customerOnly) {
      <div class="form-full-width">
        <div class="info">{{ 'comment_info_to_factory' | ni18n }}</div>
      </div>
    }
  </div>

  <form [formGroup]="form">
    <div class="trans">
      <div class="comment">
        <mat-form-field class="comment-text" subscriptSizing="dynamic">
          <textarea
            [placeholder]="'comment_info_to_customer' | ni18n"
            cdkAutosizeMinRows="2"
            [cdkTextareaAutosize]="true"
            class="kdl-no-flippers"
            formControlName="comment"
            matInput
          ></textarea>
        </mat-form-field>

        <mat-checkbox formControlName="approvedCustomer" ngDefaultControl>
          <span>{{ 'comment_approved' | ni18n }}</span>
        </mat-checkbox>
      </div>

      @if (!comment.customerOnly) {
        <div [style.width.px]="20"></div>

        <div class="comment">
          <mat-form-field class="comment-text" subscriptSizing="dynamic">
            <textarea
              cdkAutosizeMinRows="2"
              [cdkTextareaAutosize]="true"
              class="kdl-no-flippers"
              formControlName="translation"
              [placeholder]="'comment_info_to_factory' | ni18n"
              matInput
            ></textarea>
          </mat-form-field>

          <mat-checkbox formControlName="approvedFactory" ngDefaultControl>
            <span>{{ 'comment_approved' | ni18n }}</span>
          </mat-checkbox>
        </div>
      }
    </div>

    <div class="price-holder">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'comment_add_on_price_customer' | ni18n }}</mat-label>
        <input [placeholder]="'comment_add_on_price_customer' | ni18n"
               class="kdl-no-flippers"
               formControlName="price"
               matInput
               type="number">
        <span matTextSuffix>kr</span>
      </mat-form-field>

      @if (!comment.customerOnly) {
        <div [style.width.px]="10"></div>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{ 'comment_add_on_price_factory' | ni18n }}</mat-label>
          <input [placeholder]="'comment_add_on_price_factory' | ni18n"
                 class="kdl-no-flippers"
                 formControlName="labor"
                 matInput
                 type="number">
          <span matTextSuffix>€</span>
        </mat-form-field>
      }

      <div [style.width.px]="10"></div>

      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'comment_add_on_price_material' | ni18n }}</mat-label>
        <input [placeholder]="'comment_add_on_price_material' | ni18n"
               class="kdl-no-flippers" formControlName="material"
               matInput
               type="number">
        <span matTextSuffix>€ / kr</span>
      </mat-form-field>
    </div>
  </form>

  <!-- You cannot select images if no project home -->
  @if (currentNode) {
    <kdl-comment-image
      [comment]="comment"
      [customerTitle]="customerTitle"
      [factoryTitle]="factoryTitle"
      (imageRemoved)="imageListChanged()"
      (imageSelected)="imageListChanged()"
      [images]="images"
    ></kdl-comment-image>
  }
</mat-dialog-content>

@if (!form.controls.price.valid) {
  <div class="price-info">Fill Additional Customer price</div>
}

<mat-dialog-actions>
  <button (click)="save()"
          [disabled]="form.pristine || form.invalid || !currentNode"
          color="primary" mat-raised-button>
    <mat-icon class="material-icons-outlined" fontIcon="save"></mat-icon>
    <span>{{ 'common_save' | ni18n }}</span>
  </button>

  <button [mat-dialog-close]="null" mat-stroked-button>
    <mat-icon class="material-icons-outlined" fontIcon="close"></mat-icon>
    <span>{{ 'common_cancel' | ni18n }}</span>
  </button>
</mat-dialog-actions>
