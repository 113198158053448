import {IProjectImage} from '../model/project-image'
import {Pipe, PipeTransform} from '@angular/core'
import {TProjectItemType} from '../../project-viewer/model/project-node'

/**
 * Filter a list of images based on scope and/or source id
 *
 * @param images - All images that you want to filter.
 * @param scopes - The _image_ scope
 * @param id - The id of the parent, matches exactly
 */
export const filterImages = (
  images: IProjectImage[],
  scopes: TProjectItemType[] = [],
  id = ''
): IProjectImage[] =>
  images
    .filter(image => scopes.indexOf(image.scope) !== -1 || scopes.length === 0)
    .filter(image => {
      /**
       * This is to handle old images that did not have a source id.
       *
       * Nevertheless, if a source id is set we filter regardless of type
       * but make sure to send 'ALL' if you want to filter by ID as well
       */
      image.sourceId = image.sourceId || ''

      return image.sourceId.indexOf(id) !== -1
    })

@Pipe({
  name: 'imageFilter'
})
export class ImageFilterPipe implements PipeTransform {
  transform(images: IProjectImage[], scope: TProjectItemType): IProjectImage[] {
    return filterImages(images, [scope])
  }
}
