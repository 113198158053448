import {Component, Input} from '@angular/core'
import {Comment} from '../model/comment'
import {
  CommentListItemComponent
} from '../comment-list-item/comment-list-item.component'

@Component({
  selector: 'kdl-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  imports: [
    CommentListItemComponent
  ],
  standalone: true
})
export class CommentListComponent {
  @Input() comments: Comment[] = []
  @Input() showLabor = false
  @Input() showMaterial = false
  @Input() showPrice = false
  @Input() translated = false
  @Input() approvals = false
  @Input() edit = false
}
