@if (!uploading) {
  <div>
    <input #imageUpload
           (change)="onFileSelected($event.target)"
           accept=".jpg,.jpeg,.png"
           class="file-input"
           multiple type="file"/>
    <button
      (click)="imageUpload.click()"
      color="primary"
      mat-raised-button>
      @if (iconForButton) {
        <mat-icon class="material-icons-outlined">add_photo_alternate</mat-icon>
      }
      <span kdlI18n label="{{uploadButtonLabel}}"></span>
    </button>
  </div>
} @else {
  <div class="container">
    <div class="loading-indicator">
      <kdl-loading-indicator></kdl-loading-indicator>
    </div>
  </div>
}
