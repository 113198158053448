<button
  [disabled]="!path || !(openProjectService.project$ | async)?.form.lc"
  [routerLink]="[customerPath + path]"
  matTooltip="Preview customer spec"
  matTooltipClass="mat-tooltip-extra"
  class="customer" mat-mini-fab>
  <kdl-icon icon="customer-spec"></kdl-icon>
</button>

<button
  [disabled]="!path"
  [routerLink]="[factoryPath + path]"
  matTooltip="Preview carpentry order sheet"
  matTooltipClass="mat-tooltip-extra"
  class="factory"
  mat-mini-fab>
  <kdl-icon icon="factory-spec"></kdl-icon>
</button>
