<div class="holder">
  <form [formGroup]="viewOptionsForm">
    @for (vo of option.viewOptions; track option.optionSelectName) {
      <div>
        @if (vo.type === 0) {
          <div class="value-option">
            <!-- Binary, note how Enums does not work in html -->
            <mat-label>{{ vo.title }}</mat-label>
            <mat-radio-group
              [formControlName]="vo.name"
              required>
              <mat-radio-button [checked]="vo.selection === vo.values[0]"
                                [value]="vo.values[0]">{{ vo.values[0] }}
              </mat-radio-button>
              <mat-radio-button [checked]="vo.selection === vo.values[1]"
                                [value]="vo.values[1]">{{ vo.values[1] }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        }

        @if (vo.type === 1) {
          <div class="value-option"> <!-- text, id est an input -->
            <mat-form-field>
              <mat-label>{{ vo.title }}</mat-label>
              <input [formControlName]="vo.name" matInput>{{ vo.values[0] }}
            </mat-form-field>
          </div>
        }

        @if (vo.type === 2) {
          <div class="value-option">
            <mat-form-field color="accent">
              <mat-label>{{ vo.title }}</mat-label>
              <mat-select [formControlName]="vo.name">
                @for (opt of vo.values; track $index) {
                  <!--
                    Ideally, we would like to use "settingsOption" pipe here,
                    but not all values in "viewOptions" have a direct translation.
                    So we just keep it like this. Maybe in the future it can be
                    changed.    Darío 2024-05-29
                  -->
                  <mat-option [value]="opt">{{ opt }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
    }
  </form>
  <div>
    <kdl-comment-list
      [comments]="commentService.getCommentsByCommentHome(option.commentHome) | async"
      [edit]="true"
      [showLabor]="true"
      [showMaterial]="true"
      [showPrice]="true">
    </kdl-comment-list>

    <kdl-new-comment
      [source]="option.commentHome"
    ></kdl-new-comment>
  </div>
</div>
