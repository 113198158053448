import {Component, Inject} from '@angular/core'
import {
  CabinetOptionComponent
} from '../cabinet-option/cabinet-option.component'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {CabinetOption} from '../../model/cabinet-option'
import {MatIcon} from '@angular/material/icon'
import {MatButton} from '@angular/material/button'
import {NewI18nPipe} from '../../i18n/new-i18n.pipe'

export interface IEditOptionData {
  option: CabinetOption
}

@Component({
  selector: 'kdl-option-dialog',
  templateUrl: './option-dialog.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatIcon,
    MatButton,
    CabinetOptionComponent,
    NewI18nPipe
  ],
  standalone: true
})
/**
 * This is a wrapper for the CabinetOption to show it in
 * a dialog. Nothing more nothing less.
 */
export class OptionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CabinetOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditOptionData
  ) {
  }
}
