import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {Comment, CommentHomeEntity} from '../model/comment'
import {Observable, of, Subscription} from 'rxjs'
import {CommentsService} from '../services/comments.service'
import {filter} from 'rxjs/operators'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {
  ProjectViewerService
} from '../../project-viewer/services/project-viewer.service'
import {
  ProjectItemDialogComponent
} from '../../project-viewer/project-item-dialog/project-item-dialog.component'
import {
  IProjectDialogData
} from '../../project-viewer/model/project-dialog-data'
import {IProjectNode} from '../../project-viewer/model/project-node'
import {IProjectImage} from '../../images/model/project-image'
import {OpenProjectService} from '../../services/open-project.service'
import {filterImages} from '../../images/services/image-filter'
import {
  CustomerNoDisplayOptions
} from '../../customer-internal/print-view/cabinet-option/cabinet-option.component'
import {ProjectNode} from '../../project-viewer/model/project-node.class'
import {I18nModule} from '../../i18n/i18n.module'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {AsyncPipe} from '@angular/common'
import {MatInput} from '@angular/material/input'
import {MatCheckbox} from '@angular/material/checkbox'
import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {MatIcon} from '@angular/material/icon'
import {MatButton} from '@angular/material/button'
import {CommentImageComponent} from '../comment-image/comment-image.component'
import {NewI18nPipe} from '../../i18n/new-i18n.pipe'

/**
 * This is the main "Comment" edit dialog.
 */
@Component({
  selector: 'kdl-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  imports: [
    MatDialogTitle,
    I18nModule,
    MatDialogContent,
    ReactiveFormsModule,
    MatLabel,
    AsyncPipe,
    MatInput,
    MatSuffix,
    MatFormField,
    MatCheckbox,
    CdkTextareaAutosize,
    MatIcon,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    NewI18nPipe,
    CommentImageComponent
  ],
  standalone: true
})
export class CommentsComponent implements OnInit, OnDestroy {
  public form = new FormGroup({
    // Set by backend should not be modified but should be present
    id: new FormControl<string>('', {nonNullable: true}),
    type: new FormControl<string>('CT', {nonNullable: true}),
    version: new FormControl<string>('', {nonNullable: true}),
    comment: new FormControl<string>('', {nonNullable: true}),
    translation: new FormControl<string>('', {nonNullable: true}),
    approvedCustomer: new FormControl<boolean>(false, {nonNullable: true}),
    approvedFactory: new FormControl<boolean>(false, {nonNullable: true}),
    price: new FormControl<number>(null, {nonNullable: true}),
    labor: new FormControl<number>(null, {nonNullable: true}),
    material: new FormControl<number>(null, {nonNullable: true}),
    location: new FormControl<string | null>(null, {validators: [Validators.required]})
  })

  public comment: Comment

  /**
   * Titles for images
   */
  public customerTitle: string
  public factoryTitle: string

  /**
   * This may or may not be populated with images
   *
   */
  public images: IProjectImage[] = []

  /**
   * Use this to disable duplicate on already duplicated
   * comments. Used as semaphore based on comment location
   * when first loaded.
   */
  public canDuplicate = true

  /**
   * If we are homeless this is not defined
   */
  public currentNode: ProjectNode | undefined


  private commentHome: CommentHomeEntity

  /**
   * New node is only used to detect if the node
   * home has changed. Not important for other purposes
   */
  private newNode: IProjectNode | null = null

  private sub$ = new Subscription()

  constructor(
    public commentsService: CommentsService,
    public dialogRef: MatDialogRef<CommentsComponent>,
    public projectViewerService: ProjectViewerService,
    @Inject(MAT_DIALOG_DATA) private data: { comment: Comment; duplicate: false },
    private dialog: MatDialog,
    private openProjectService: OpenProjectService
  ) {
  }

  public ngOnInit(): void {
    this.comment = this.data.comment
    this.form.controls.approvedCustomer.setValue(this.comment.factoryOnly)
    this.canDuplicate = this.data.duplicate
    this.commentHome = Object.assign({}, this.comment.commentHome)
    this.form.patchValue(this.comment as any)
    if (!this.canDuplicate) {
      this.form.controls.price.setValue(null)
      this.form.controls.price.setValidators([Validators.required])
    }

    // Point our images to the comment images
    this.sub$ = this.openProjectService.project$
      .pipe(filter(Boolean))
      .subscribe({
        next: (project => {
          this.images = filterImages(project.images, undefined, this.comment.id)
        })
      })

    this.currentNode = this.projectViewerService.nodes.get(this.commentHome.id)
    // If we do not have a home we start our selves again!!
    if (this.currentNode) {
      this.comment.commentHome.location = this.setNameOfLocation(this.currentNode)
      this.form.controls.location.setValue('a value') // Does not really matter what we set
      if (this.currentNode.type === 'OPTION' &&
        CustomerNoDisplayOptions.indexOf(this.currentNode.item.optionSelectName) !== -1) {
        this.form.controls.comment.setValue('Comments to customer will not be shown', {emitEvent: false})
        this.form.controls.comment.disable({emitEvent: false})
        this.form.controls.price.disable({emitEvent: false})
        this.form.controls.approvedCustomer.disable({emitEvent: false})
      }
    } else {
      // Ok this comment has no home, we open the edit location dialog
      this.form.disable({emitEvent: false})
      this.canDuplicate = false
      this.editLocation()
    }

    /**
     * To be able to set the "approved" status we will need to listen
     * to each of the customer and factory comments. The rule is to mark
     * the other as 'not approved' when the first change.
     */
    this.form.controls.comment.valueChanges
      .subscribe({
        next: (val: string) => {
          this.form.controls.approvedFactory.setValue(false)
          this.form.controls.approvedCustomer.setValue(true)
          this.comment.comment = val
          this.setTitles()
        }
      })

    this.form.controls.translation.valueChanges
      .subscribe({
        next: (val: string) => {
          this.form.controls.approvedCustomer.setValue(false)
          this.form.controls.approvedFactory.setValue(true)
          this.comment.translation = val
          this.setTitles()
        }
      })

    /**
     * If you touch anything duplication is no longer possible
     */
    this.form.valueChanges.subscribe({
      next: () => this.canDuplicate = false
    })

    this.form.controls.price.valueChanges.pipe().subscribe({
      next: (price: number) => {
        this.form.controls.labor.setValue(Math.round(price / 30), {emitEvent: false})
      }
    })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public imageListChanged(): void {
    this.form.markAsDirty()
  }

  /**
   * Save and if needed first moves the comment.
   * Save is otherwise handled by the callee.
   */
  public save(): void {
    /**
     * See if we can always move the comment instead of this if.
     */
    const obs: Observable<any> = this.newNode ?
      this.commentsService.moveComment(this.comment, this.newNode) : of(null)
    obs.subscribe({
      next: () => {
        /**
         * It is hard to know who calls this. Check for dialog.open(CommentsComponent,
         * whoever calls must make sure to save the image references.
         */
        this.dialogRef.close(Object.assign(this.comment, this.form.getRawValue()))
      }
    })
  }

  public duplicate(): void {
    let comment = new Comment()
    /**
     * Duplicate removes the home but leaves everything else intact
     */
    comment = comment.duplicate(this.comment)
    this.dialogRef.close()
    this.dialog.open(CommentsComponent, {data: {comment, duplicate: false}})
      .afterClosed()
      .pipe(
        filter(Boolean))
      .subscribe({
        next: () => {
          this.openProjectService.triggerChanges({projectChange: true})
        }
      })
  }

  public editLocation(): void {
    const data: IProjectDialogData = {
      title: 'newLocation',
      selected: this.currentNode,
      filter: ['IMAGE', 'COMMENT']
    }
    // Cabinet comments can only be moved within the same cabinet.
    // See: https://linaf.slack.com/archives/C03NJUWCSRJ/p1704358742871359
    if (['CABINET', 'OPTION'].indexOf(this.commentHome.type) !== -1 && this.commentHome.id) {
      data.start = this.commentHome.id.split('_')[0]
    }
    this.dialog.open(ProjectItemDialogComponent, {
      data
    }).afterClosed().pipe(
      filter(Boolean))
      .subscribe({
        next: (newNode: ProjectNode) => {
          // Once the new location is set, the form is enabled again
          this.form.enable({emitEvent: false})

          // Save "newNode" and set commentHome's location to have something
          // to show in the UI regarding the changes. User feedback basics.
          // But real modification of commentHome will happen after "save()".
          this.newNode = newNode
          this.currentNode = newNode
          this.comment.commentHome.location = this.setNameOfLocation(newNode)
          this.form.controls.location.setValue(this.newNode.name) // Does not really matter what we set
          this.form.markAsDirty()

          // If the comment is a duplication, meaning that it has no
          // commentHome.id and canDuplicate is false, we set commentHome here.
          if (!this.comment.commentHome.id && !this.canDuplicate) {
            this.comment.commentHome.id = newNode.id
            this.comment.commentHome.type = newNode.type
          }
        }
      })
  }

  private setTitles(): void {
    this.customerTitle = this.comment.comment
    this.factoryTitle = this.comment.translation
  }

  private setNameOfLocation(node: IProjectNode): string {
    if (node.type === 'CABINET' || node.type === 'OPTION') {
      return node.nameParts
        .join(' - ') + ' '
    }
    return ''
  }
}
