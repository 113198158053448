import {Component, EventEmitter, Input, Output} from '@angular/core'
import {Comment} from '../model/comment'
import {IProjectImage} from '../../images/model/project-image'
import {
  BlueprintUploadComponent
} from '../../images/blueprint-upload/blueprint-upload.component'
import {
  ImageListItemComponent
} from '../../images/image-list-item/image-list-item.component'

@Component({
  selector: 'kdl-comment-image',
  templateUrl: './comment-image.component.html',
  imports: [
    BlueprintUploadComponent,
    ImageListItemComponent
  ],
  standalone: true
})
export class CommentImageComponent {

  @Input() comment: Comment

  @Input() customerTitle: string
  @Input() factoryTitle: string
  /**
   * Pass this in, and if images are returned, this will
   * be populated.
   */
  @Input() images: IProjectImage[] = []

  @Output() imageSelected = new EventEmitter<IProjectImage>()
  @Output() imageRemoved = new EventEmitter<boolean>()

}
