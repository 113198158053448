<div class="image-row">
  <div class="image-and-title">
    <div class="image-holder">
      @if (thumbs) {
        <img (click)="viewImage()"
             [src]="viewUrl || './assets/photo_white.png'"
             [style.max-width]="'200px'" alt="thumb" height="70px">
      }
    </div>

    <div (click)="viewDetails()" class="title-and-caption">
      {{ image.factoryTitle || image.customerTitle || image.title || image.displayName }}
    </div>
  </div>

  <div class="filler"></div>

  <div class="buttons">
    @if (showLocation && image.scope === 'PROJECT') {
      <div class="location-indicator">
        <div>
          <img [matTooltip]="'imageInCustomerPDF' | i18n | async" alt="customer"
               height="23"
               ngSrc="assets/kundunderlag.svg"
               width="23">
        </div>

        @if (image.factoryTitle) {
          <div>
            <img [matTooltip]="'imageInCarpentryOrderSheet' | i18n | async"
                 alt="carpentry" height="23"
                 ngSrc="assets/snickeriunderlag.svg" width="23">
          </div>
        }
      </div>
    }

    <button
      (click)="openProjectService.removeImage(image.id); removed.emit(true)"
      color="warn"
      mat-icon-button>
      <mat-icon class="material-icons-outlined" fontIcon="delete"></mat-icon>
    </button>

    @if (image.scope === 'NOTEPAD') {
      <button
        (click)="moveImage()"
        [matTooltip]="'moveImage' | i18n | async"
        color="accent"
        mat-icon-button>
        <mat-icon class="material-icons-outlined"
                  fontIcon="exit_to_app"></mat-icon>
      </button>
    }

    @if (!thumbs) {
      <button
        (click)="viewImage()"
        color="accent"
        mat-icon-button>
        <mat-icon class="material-icons-outlined" fontIcon="image"></mat-icon>
      </button>
    }
  </div>
</div>
