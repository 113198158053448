import {Component, Input} from '@angular/core'
import {Comment} from '../model/comment'
import {CommentsService} from '../services/comments.service'
import {MatDialog} from '@angular/material/dialog'
import {CommentsComponent} from '../comments/comments.component'
import {filter} from 'rxjs/operators'
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog/confirm-dialog.component'
import {I18nModule} from '../../i18n/i18n.module'
import {MatIcon} from '@angular/material/icon'
import {NgClass} from '@angular/common'
import {MatIconButton} from '@angular/material/button'

@Component({
  selector: 'kdl-comment-list-item',
  templateUrl: './comment-list-item.component.html',
  styleUrls: ['./comment-list-item.component.scss'],
  imports: [
    I18nModule,
    MatIcon,
    NgClass,
    MatIconButton
  ],
  standalone: true
})
export class CommentListItemComponent {

  @Input() comment: Comment = new Comment('')

  @Input() showPrice = true

  @Input() showMaterial = true

  @Input() showLabor = true

  @Input() isFirst = true

  @Input() approvals = false

  @Input() edit = false

  @Input() customer = true

  @Input() factory = false


  constructor(
    private commentsService: CommentsService,
    public dialog: MatDialog
  ) {
  }

  public editComment(): void {
    this.dialog.open(CommentsComponent, {data: {comment: this.comment, duplicate: true}})
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          this.commentsService.editComment()
        }
      })
  }

  public delete(comment: Comment): void {
    ConfirmDialogComponent.open(this.dialog, {
      html: `Do you want to delete the comment?`
    }, true)
    .afterClosed().pipe(
      filter(Boolean)
    ).subscribe({
      next: () => {
        this.commentsService.deleteComment(comment).subscribe()
      }
    })
  }
}
