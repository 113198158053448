import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {filter, map, tap} from 'rxjs/operators'
import {ProjectAppliance} from '../../appliances/model/appliance'
import {CounterTop} from '../../counter-top/model/counter-top'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {IProjectNode} from '../../project-viewer/model/project-node'
import {OpenProjectService} from '../../services/open-project.service'
import {IProject} from '../../services/project-types'
import {Comment, CommentHomeEntity, Comments, IComment} from '../model/comment'
import {ProjectCommentHelper} from './project-comment-helper'

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  /**
   * All comments in the current "project", as
   * observable so that no one else can write to it.
   *
   * We publish all comments here as real "Comment" objects
   */
  public projectComments$: Observable<Comment[]>

  /**
   * These a are comments on a project.
   */
  private pProjectComments$ = new BehaviorSubject<Comment[]>([])

  /**
   * We switch over to having a project injected
   *
   * @private
   */
  private project: IProject | null = null

  constructor(
    private openProjectService: OpenProjectService
  ) {
    this.projectComments$ = this.pProjectComments$.asObservable()

    /**
     * Only when a new project arrives we send new project comments?
     */
    this.openProjectService.project$.pipe(
      tap(() => this.pProjectComments$.next([])),
      filter(Boolean)
    ).subscribe({
      next: (project: IProject) => {
        this.project = project
        this.setProject(project)
      }
    })
  }

  /**
   * Get yourself a constantly updated list of comments on _Cabinet_
   * _includes_ option comments.
   *
   * @param id - If we do not have an index, we might have an id
   */
  public getCommentsForCabinet(id: string): Observable<Comment[]> {
    return this.projectComments$.pipe(
      map((comments: Comment[]) =>
        comments.filter((comment: Comment) =>
          id.indexOf(comment.commentHome.id) !== -1
        ))
    )
  }

  public getCommentsByCommentHome(home: CommentHomeEntity): Observable<Comment[]> {
    return this.projectComments$.pipe(
      map((comments: Comment[]) => comments.filter(c => c.commentHome.id === home.id))
    )
  }

  /**
   * Creates a new comment.
   */
  public createComment(comment: Comment): Observable<Comment> {
    const helper = new ProjectCommentHelper(this.project)
    helper.addComment(comment)
    this.openProjectService.triggerChanges({projectChange: true})
    return of(comment)
  }

  /**
   * ads
   */
  public editComment(): void {
    this.openProjectService.triggerChanges({projectChange: true})
  }

  public moveComment(comment: Comment, newLocation: IProjectNode): Observable<IProjectNode> {
    // Start by removing it from its current location
    const helper = new ProjectCommentHelper(this.project)
    helper.removeComment(comment)
    const target: Comments = newLocation.item
    Object.assign(comment.commentHome, target.commentHome)
    helper.addComment(comment)
    return of(newLocation)
  }

  /**
   * Delete a comment, the specific version is deleted and removed
   * from the project
   */
  public deleteComment(comment: Comment): Observable<void> {
    const helper = new ProjectCommentHelper(this.project)
    helper.removeComment(comment)
    this.openProjectService.removeImagesBySourceId(comment.id)
    this.openProjectService.triggerChanges({projectChange: true})
    return of(null)
  }

  private setProject(project: IProject | null): void {
    let comments: Comment[] = project.comments

    // Make sure all comments are upgrade to commentHome
    comments.forEach((comment: Comment) => {
      comment.commentHome = comment.commentHome || {
        type: 'PROJECT',
        id: this.project.id
      }
    })
    // "Key" is 1, 2, 3 etc.
    let cabinetComments = Object.keys(project.cabinets)
      .map((key: string) => {
        // Normally all cabinets have the comments' property, but not always, maybe
        const cabComments = project.cabinets[key].comments || []
        const cabinet: ProdboardCabinet = project.cabinets[key]
        const optionComments = Object.keys(cabinet)
          .filter((k: string) => cabinet[k])
          .map((k: string) => {
            return cabinet[k].comments
          })
          .filter((c: IComment) => c)
          .flat(1)

        optionComments.forEach((comment: Comment) => {
          comment.commentHome = comment.commentHome || {
            type: 'OPTION',
            id: `${cabinet.uid || cabinet.index}_${comment.optionName}`
          }
        })
        cabComments.forEach((comment: Comment) => {
          const id = `${cabinet.uid || cabinet.index}`
          comment.commentHome = comment.commentHome || {type: 'CABINET', id}
          // This is for malformed comments that I have discovered
          if (!comment.commentHome.id) {
            comment.commentHome.id = id
          }
        })

        return cabComments.concat(optionComments)
      })
    cabinetComments = cabinetComments.flat(1)
    comments = comments.concat(cabinetComments)

    // Add all appliance comments
    this.project.appliances.forEach((appliance: ProjectAppliance) => {
      comments = comments.concat(appliance.comments)
    })

    this.project.counterTops.forEach((counterTop: CounterTop) => {
      comments = comments.concat(counterTop.comments)
    })
    //
    comments.forEach((comment: Comment) => {
      /**
       * Migrate all "name" properties to type.
       */
      const temp: any = comment.commentHome
      if (temp.name) {
        temp.type = temp.name
        delete temp.name
      }
    })
    this.pProjectComments$.next(comments)
  }
}

