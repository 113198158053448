<div class="container">
    <div class="form-holder">
        <mat-label class="label" kdlI18n label="moveToLabel"></mat-label>
      <mat-radio-group [formControl]="moveSelector" ngDefaultControl>
            <mat-radio-button value="PROJECT"><span kdlI18n label="moveImageToProject"></span></mat-radio-button>
            <mat-radio-button value="CABINET"><span kdlI18n label="cabinet"></span></mat-radio-button>
        </mat-radio-group>
        <div *ngIf="moveSelector.value === 'CABINET'" class="cabinet-holder">
            <mat-form-field class="select">
                <mat-label kdlI18n label="selectCabinet"></mat-label>
                <mat-select
                        [formControl]="cabinetSelector"
                        [placeholder]="'selectCabinet' | i18n| async">
                  <mat-option *ngFor="let cabinet of openProjectService.cabinets$ | async"
                              [value]="cabinet"> {{cabinet.index}}. {{cabinet.cat}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-label class="label" kdlI18n label="addToLabel"></mat-label>
            <div class="approve-container">
              <mat-checkbox [formControl]="approvedCustomer"><span kdlI18n label="customerSpec"></span></mat-checkbox>
                <mat-checkbox [formControl]="approvedFactory"><span kdlI18n label="factoryOrder"></span></mat-checkbox>
            </div>
        </div>
        <div *ngIf="moveSelector.value === 'PROJECT'" class="input-holder">
            <mat-form-field>
                <mat-label kdlI18n label="customerTitle"></mat-label>
                <input [formControl]="customerImageTitle"
                       matInput
                       type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label kdlI18n label="factoryTitle"></mat-label>
                <input [formControl]="factoryImageTitle"
                       matInput
                       type="text">
            </mat-form-field>
        </div>
        <div class="button-container">
            <button
                    (click)="move()"
                    color="accent"
                    disabled="{{moveSelector.value === 'CABINET' && cabinetSelector.value === null}}"
                    mat-raised-button
            >
                <span kdlI18n label="move"></span>
                <mat-icon class="material-icons-outlined" fontIcon="exit_to_app"></mat-icon>
            </button>
            <button (click)="dialogRef.close(false)" color="warn" mat-stroked-button>
                <span kdlI18n label="cancel"></span>
                <mat-icon class="material-icons-outlined" fontIcon="close"></mat-icon>
            </button>
        </div>
    </div>
</div>
