import {Component, Input, OnInit} from '@angular/core'
import {CabinetOption, TOptionSelectName} from '../../../model/cabinet-option'
import {Comment} from '../../../comments/model/comment'
import {ProdboardCabinet} from '../../../model/cabinet/prodboard-cabinet'
import {FrameWidth} from '../../../model/frame-width/frame-width'

export const CustomerNoDisplayOptions: TOptionSelectName[] = ['DoorType', 'Skirting', 'Hinges', 'Legs']

@Component({
  selector: 'kdl-cabinet-option-customer-internal',
  templateUrl: './cabinet-option.component.html',
  styleUrls: ['../sections/sections.scss']
})
export class CabinetOptionComponent implements OnInit {
  @Input() public options: CabinetOption[] = []

  @Input() public cabinet: ProdboardCabinet = {getScribings: () => ''} as any

  public optionsList: string[] = []

  /**
   * Special formatting of dishwashers?
   */
  public dishwasher = false

  ngOnInit(): void {
    // Check if this cabinet has scribings
    // If so update the FrameOption if any

    const frame = this.options.find((o: CabinetOption) => o.optionSelectName === 'FrameWidth') as FrameWidth
    if (frame) {
      frame.addScribings(this.cabinet.getScribings())
    }
    // Filter for active and some banned (?) options
    this.options
      .filter(option => option.active)
      .filter((option: CabinetOption) => CustomerNoDisplayOptions.indexOf(option.optionSelectName) === -1)
      .forEach((option: CabinetOption) => {
        const customerListing = option.getCustomerListing('c')
        customerListing.forEach((opt: string) => {
          /**
           * Dishwashers are special, really special.
           * We here make the assumption that they only have one option
           * and present it as such. It is just a "cover"? Will bite
           * us in the future perhaps?
           */
          if (this.cabinet.isDishwasherCabinet) {
            if (option.optionSelectName === 'FrameWidth' && !this.dishwasher) {
              this.optionsList.push(`*) ${opt}`)
              this.dishwasher = true
              return
            }
          }
          const marker = option.optionSelectName === 'Scribings' ? '*)' : '-'
          this.optionsList.push(`${marker} ${opt}`)
        })
        this.optionsList.push(this.commentsToList(option))
      })
  }

  public commentsToList(option: CabinetOption): string {
    return option.comments.map((comment: Comment) => comment.comment).join(', ')
  }

}
