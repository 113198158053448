<kdl-blueprint-upload
  [customerTitle]="customerTitle"
  iconForButton="add_photo_alternate"
  [factoryTitle]="factoryTitle"
  [scope]="comment.commentHome.type"
  (images)="imageSelected.emit($event)"
  [sourceId]="comment.id"
  [style.padding.px]="10"
></kdl-blueprint-upload>

@for (image of images; track $index) {
  <kdl-image-list-item
    (removed)="imageRemoved.emit($event)"
    [image]="image" [thumbs]="true"
  ></kdl-image-list-item>
}

